import {
    Spinner,
    ListItem,
    DeleteIcon,
    CellActionButton,
    theme,
    withTrace,
} from '@local/web-design-system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Checkbox, Tooltip } from '@mui/material';
import { compose } from 'redux';
import { withRouter } from 'src/routes/withRouter';
import { withStyles } from 'src/styles/utils';
import React from 'react';

import { i18n } from '../Notifications.i18n';

import { connectToState } from './NotificationsRow.connect';
import { styles } from './NotificationsRow.styles';
import { AllProps, RowData } from './NotificationsRow.types';

export function NotificationsRowBase({
    messageData,
    fields,
    deleteMessage,
    setReadStatus,
    messageUid,
    intl,
    isDeleting,
    classes,
    navigate,
    instanceUuid,
    tray = false,
    closeTray = () => undefined,
    applyTrace,
}: AllProps) {
    const title = messageData.read
        ? intl.formatMessage(i18n.markOneUnread)
        : intl.formatMessage(i18n.markOneRead);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            setReadStatus(messageUid, !messageData.read);
        },
        [messageUid, messageData],
    );

    const stopPropagation = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => event.stopPropagation(),
        [],
    );
    const deleteMessageFn = React.useCallback(() => deleteMessage(messageUid), []);

    const checkBox = (
        <Tooltip title={title} className={classes.tooltip}>
            <Checkbox
                icon={<div className={classes.unreadIcon} />}
                checkedIcon={<FiberManualRecordIcon classes={{ root: classes.readIcon }} />}
                onChange={onChange}
                onClick={stopPropagation}
                checked={!messageData.read}
                className="MuiTableCell-paddingCheckbox"
                classes={{ root: tray ? classes.trayCheckbox : classes.checkbox }}
                {...applyTrace('read-checkbox')}
            />
        </Tooltip>
    );

    const deleteAction = (
        <CellActionButton
            {...applyTrace('delete')}
            hasConfirmation={false}
            action={deleteMessageFn}
            ButtonProps={{ disabled: isDeleting && tray }}
            dialogTitle={intl.formatMessage(i18n.dialogTitle)}
            cancelText={intl.formatMessage(i18n.dialogCancel)}
            confirmText={intl.formatMessage(i18n.dialogConfirm)}
            dialogContent={intl.formatMessage(i18n.dialogContent)}
            icon={isDeleting && tray ? <Spinner size={21} position="relative" /> : <DeleteIcon />}
            classes={tray ? { button: classes.button } : {}}
        />
    );

    const rowClasses = {
        tableCell: classes.tableCell,
        ...(!messageData.read && { root: classes.root }),
    };

    const navigateFn = React.useCallback((_event: Event) => {
        setReadStatus(messageUid, true);
        const { link } = messageData;
        // Safe destructuring
        const stringUrl = link?.url ?? null;
        try {
            const url = new URL(stringUrl);

            if (url.hostname === window.location.hostname) {
                if (url.pathname.split('/')[1] === 'api') {
                    window.open(url.href, url.hostname);
                } else {
                    navigate(url.pathname + url.search);
                }
            } else {
                window.open(url.href, url.hostname);
            }
        } catch {
            // eslint-disable-next-line no-console
            console.error(
                `Notification ${messageData?.topic}: ${messageData?.message} lacks a url`,
            );
        }

        if (tray) {
            closeTray();
        }
    }, []);

    const rowData: RowData = { ...messageData, currentInstanceUUID: instanceUuid, id: messageUid };
    const trayIcons = {
        paddingLeft: 0,
        verticalAlign: 'top',
    };

    return (
        <ListItem
            {...applyTrace('list-item')}
            item={rowData}
            fields={fields}
            checkbox={checkBox}
            classes={rowClasses}
            actions={deleteAction}
            clickHandler={navigateFn}
            actionStyles={tray ? { ...trayIcons, paddingRight: theme.spacing(3) } : undefined}
            checkboxStyles={tray ? trayIcons : undefined}
        />
    );
}

const withProps = compose(
    connectToState,
    withStyles(styles),
    withTrace('notification-row'),
    withRouter,
);

export const NotificationsRow = withProps(NotificationsRowBase) as React.FC<any>;
export default NotificationsRow;
